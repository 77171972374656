export const packagingWeightCalculator = {
  data () {
    return {
      VOL_WEIGHT_CALC_FACTOR_CM: 5000,
      VOL_WEIGHT_CALC_FACTOR_IN: 139
    }
  },
  methods: {
    volWeightCalcFactor (weightUnit) {
      return (weightUnit === 'lbs') ? this.VOL_WEIGHT_CALC_FACTOR_IN : this.VOL_WEIGHT_CALC_FACTOR_CM
    },
    calculatePhysicalWeight (parcels) {
      let result = 0.0
      parcels.forEach((parcel) => {
        let w = this.calculateSinglePhysicalWeight(parcel)
        result += w
      })
      return result
    },
    calculateSinglePhysicalWeight (parcel) {
      if (parcel.weight && parcel.quantity) {
        return parseFloat(parcel.weight) * parseFloat(parcel.quantity)
      } else {
        return 0
      }
    },
    calculateVolWeight (parcels, weightUnit) {
      let result = 0.0

      parcels.forEach((parcel) => {
        let w = this.calculateSingleVolWeightQuantity(parcel, weightUnit)
        result += w
      })
      return result
    },
    calculateSingleVolWeightQuantity (parcel, weightUnit) {
      if (parcel.length && parcel.height && parcel.width && parcel.quantity) {
        return (this.getDimValue(parcel.length, weightUnit) * this.getDimValue(parcel.height, weightUnit) * this.getDimValue(parcel.width, weightUnit) * parseFloat(parcel.quantity)) / this.volWeightCalcFactor(weightUnit)
      } else {
        return 0
      }
    },
    getDimValue (value, weightUnit) {
      return Math.ceil(value)
    },
    calculateTotalInvoicedWeight (parcels, weightUnit) {
      let result = 0.0
      parcels.forEach((parcel) => {
        let singleVol = this.calculateSingleVolWeightQuantity(parcel, this.volWeightCalcFactor(weightUnit))
        let singlePhysical = this.calculateSinglePhysicalWeight(parcel)

        if (singlePhysical > singleVol) {
          result += singlePhysical
        } else {
          result += singleVol
        }
      })
      return result
    }
  }
}
