import { Exclude, Expose, Transform, Type } from 'class-transformer'
import { AbstractModel } from '@/models/abstract-model'
import { SurchargeDiscount } from '@/models/surcharge-discount'

export enum SURCHARGE_CODES {
  SURCHARGE_CODE_PICKUP_REMOTE_AREA = 'PRA',
  SURCHARGE_CODE_DELIVERY_ADDRESS_CHANGE = 'ACH',
  SURCHARGE_CODE_DOOR_DELIVERY = 'DD',
  SURCHARGE_CODE_EMERGENCY_SITUATION = 'CE',
  SURCHARGE_CODE_DELIVERY_ADDRESS_CORRECTION = 'ACO',
  SURCHARGE_CODE_INSURANCE = 'CI',
  SURCHARGE_CODE_REMOTE_AREA_DELIVERY = 'CRA',
  SURCHARGE_CODE_FUEL_SURCHARGE = 'CF',
  SURCHARGE_CODE_OVERWEIGHT = 'WO',
  SURCHARGE_CODE_OVERSIZE = 'SO',
  SURCHARGE_CODE_EXTREME_OVERSIZE = 'SOE',
  SURCHARGE_CODE_SATURDAY_PICKUP = 'PS',
  SURCHARGE_CODE_WAIT_TIME = 'CT',
  SURCHARGE_CODE_RETURN_IMPOSSIBLE_DELIVERY = 'RDI',
  SURCHARGE_CODE_IMPOSSIBLE_DELIVERY = 'DI',
  SURCHARGE_CODE_TEMP_STORAGE = 'CS',
  SURCHARGE_CODE_RESIDENTAL_DELIVERY = 'DR',
  SURCHARGE_CODE_SPECIAL_HANDLING = 'CH',
  SURCHARGE_CODE_INDIRECT_SIGNATURE_REQUIRED = 'DSIE',
  SURCHARGE_CODE_SIGNATURE_ON_DELIVERY = 'DSI',
  SURCHARGE_CODE_MULTIPIECE = 'CM',
  SURCHARGE_CODE_DELIVERY_0900 = 'E0900',
  SURCHARGE_CODE_DELIVERY_1030 = 'E1030',
  SURCHARGE_CODE_DELIVERY_1200 = 'E1200',
  SURCHARGE_CODE_PREMIUM_PICKUP_SERVICE = 'E-PS',
  SURCHARGE_CODE_REMOTE_DOOR_DELIVERY = 'DDR',
  SURCHARGE_CODE_SATURDAY_DELIVERY = 'DS',
  SURCHARGE_CODE_GENERAL_SURCHARGE = 'Z',
  SURCHARGE_CODE_PICKUP = 'P',
  SURCHARGE_CODE_CUSTOM_TAXES = 'IT',
  SURCHARGE_CODE_WRONG_SHIPMENT = 'CW',
  SURCHARGE_CODE_PROOF_OF_DELIVERY = 'DP',
  SURCHARGE_CODE_UNSUCCESSFUL_PICKUP_ATTEMPT = 'PA',
  SURCHARGE_CODE_FORMAL_EXPORT_REQUEST = 'SCER',
  SURCHARGE_CODE_REDIRECT = 'CREX',
  SURCHARGE_CODE_INCORRECT_DECLARATION = 'SI',
  SURCHARGE_CODE_FORBIDDEN_ITEM = 'SF',
  SURCHARGE_CODE_DELIVERY_WITH_APPOINTMENT = 'DA',
  SURCHARGE_CODE_PEAK_SEASON = 'CP'
}

@Exclude()
export class Surcharge extends AbstractModel {
  @Expose({ name: 'id' })
  public id: number | null = null

  @Expose({ name: 'carrier_surcharge_name_translated' })
  public carrierSurchargeNameTranslated: string | undefined

  @Expose({ name: 'vendor_net_amount' })
  @Transform(value => { return (value === null) ? 0 : parseFloat(value) }, { toClassOnly: true })
  public vendorNetAmount: number = 0

  @Expose({ name: 'net_amount' })
  @Transform(value => { return (value === null) ? null : parseFloat(value) }, { toClassOnly: true })
  public netAmount: number | null = null

  @Expose({ name: 'public_net_amount' })
  @Transform(value => { return (value === null) ? null : parseFloat(value) }, { toClassOnly: true })
  public publicNetAmount: number | null = null

  @Expose({ name: 'api_net_amount' })
  @Transform(value => { return (value === null) ? null : parseFloat(value) }, { toClassOnly: true })
  public apiNetAmount: number | null = null

  @Expose({ name: 'surcharge_discount_id' })
  public surchargeDiscountId: number | undefined

  @Expose({ name: 'surcharge_discount' })
  @Type(() => SurchargeDiscount)
  public surchargeDiscount: SurchargeDiscount | undefined

  @Expose({ name: 'reseller_surcharge_discount_id' })
  public resellerSurchargeDiscountId: number | undefined

  @Expose({ name: 'reseller_surcharge_discount' })
  @Type(() => SurchargeDiscount)
  public resellerSurchargeDiscount: SurchargeDiscount | undefined

  @Expose({ name: 'vat_rate' })
  @Transform(value => { return (value === null) ? 0 : parseFloat(value) }, { toClassOnly: true })
  public vatRate: number = 0

  @Expose({ name: 'surcharge_code', toClassOnly: true })
  public surchargeCode: string = ''

  @Expose({ name: 'total_amount' })
  @Transform(value => { return (value === null) ? 0 : parseFloat(value) }, { toClassOnly: true })
  public totalAmount: number = 0

  @Expose({ name: 'vat_amount' })
  @Transform(value => { return (value === null) ? 0 : parseFloat(value) }, { toClassOnly: true })
  public vatAmount: number = 0
}
