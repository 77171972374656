
// @ts-ignore
import mdbAvatar from 'mdbvue/lib/components/mdbAvatar'
// @ts-ignore
import { Dashboards, UserRoles } from '@/common/user.roles'
import CorporateAccountModal from '@/components/CorporateAccountModal.vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import BasicComponent from '@/views/BasicComponent'
import mdbSideNav2 from '@/overrides/mdb-components/SideNav.vue'
import { Styles } from '@/mixins/styles'
import { Invoice } from '@/models/invoice'
import { plainToClass } from 'class-transformer'
import { UsersApiService } from '@/common/api/users.api.service'
import get from 'lodash/get'
import { GraphQLResponse } from '@/common/api/graphql.service'

@Component({
  mixins: [Styles],
  components: {
    mdbSideNav2,
    mdbAvatar
  }
})

export default class SideBar extends BasicComponent {
  @Prop(Boolean) public isSlimMenu!: boolean
  @Prop(Boolean) public show!: boolean

  public slimCollapsed: boolean = true
  public dashboardsNames: any = Dashboards
  public navigation: any[] = []
  public dataForConfiguration: GraphQLResponse | undefined = undefined

  public async mounted () {
    if (this.$currentUser) {
      this.dataForConfiguration = await UsersApiService.dataForSidebarConfiguration()

      this.navigation = []
      this.prepareDashboardsLinks()
      this.prepareShopLinks()
      this.prepareShipmentsLinks()
      this.prepareInvoicingLinks()
      this.prepareResellerLinks()
      this.prepareConfigurationLinks()
      this.prepareDevelopersLinks()
      this.prepareAssociatesLinks()
      this.prepareSystemLinks()
      this.prepareHelpLinks()

      await this.fetchUnpaidOrders()
    }
  }

  public async fetchUnpaidOrders (): Promise<void> {
    if (!this.$can('index', 'ClearingCharge')) return

    const unpaidOrders: Array<object> = get(this.dataForConfiguration, 'data.data.me.orders')
    const alertIcon: string = unpaidOrders instanceof Array && unpaidOrders.length > 0 ?
      `<span class="alert-bell">
        <a href="${this.$redirectUrl}shipping/clearing_charges?&payment_status[]=3&order_type[]=6"><i class="fad fa-bells text-danger" title="${this.$t('invoicing_submenu.unpaid_clearing_charge_icon')}"></i></a>
      </span>` : ''

    const invoicingGroup: {[key: string]: any} | undefined = this.navigation.find((group) => group.name === this.$t('invoicing_name'))
    const clearingCharges: {[key: string]: any} | undefined = invoicingGroup?.children.find((page: Record<string, any>) => page.name === this.$t('invoicing_submenu.clearing_charges_name'))
    if (clearingCharges) clearingCharges.alertIcon = alertIcon
  }

  public get menuIsShown () {
    return this.show
  }

  public set menuIsShown (isShow: boolean) {
    this.$emit('showMenu', isShow)
  }

  public currentUserDiscountProfileState () {
    if (this.$currentUser) {
      return this.$currentUser.discountProfile && this.$currentUser.discountProfile && this.$currentUser.discountProfile.pickup_availability > 0
    } else {
      return false
    }
  }

  public whiteLabelAccessRules () {
    if (this.$who.adminCSorAccounting(this.$currentUser.role) || this.$who.isVendorStaff(this.$currentUser)) {
      return true
    } else if (this.$who.isCorporateAdministrator(this.$currentUser) || this.$who.isWhiteLabelAdmin(this.$currentUser)) {
      return true
    } else if (!this.$currentUser.whiteLabelId && (this.$currentUser.role !== UserRoles.USER_ROLE_CORPORATE_SUPERVISOR && !this.$who.isCorporateUser(this.$currentUser))) {
      return true
    }
    return false
  }

  public showSubsidiesForWL () {
    if (this.$who.belongsToWhiteLabel(this.$currentUser) && this.$currentWhiteLabel) {
      return this.$currentWhiteLabel.disableShopIntegration
    }
    return false
  }

  public myShopsAccessRules () {
    return (this.$currentUser.role >= 90 || this.$who.isVendorAdmin(this.$currentUser))
      || (this.$who.isCorporateAdministrator(this.$currentUser) && this.$currentUser.shopsCount > 0)
      || (this.$who.isCorporateUser(this.$currentUser) && this.$currentUser.shopsCount > 0)
      || (this.$who.isCorporateSupervisor(this.$currentUser) && this.$currentUser.shopsCount > 0)
      || (this.$currentUser.role === UserRoles.USER_ROLE_USER && this.$currentUser.shopsCount > 0)
  }

  public resellerLink () {
    if (this.$currentUser.adminableWhitelabels && this.$currentUser.adminableWhitelabels.length > 0) {
      if (this.$can('edit_reseller', this.$currentUser.adminableWhitelabels[0])) {
        return `${this.$redirectUrl}white_labels/${this.$currentUser.adminableWhitelabels[0].id}/edit_reseller`
      } else if (this.$can('show_reseller', this.$currentUser.adminableWhitelabels[0])) {
        return `${this.$redirectUrl}white_labels/${this.$currentUser.adminableWhitelabels[0].id}/show_reseller`
      } else if (this.$can('index', 'WhiteLabel')) {
        return `${this.$redirectUrl}white_labels?reseller=true`
      }
    }
    return '#'
  }

  public async corporateAccountsLink () {
    if (this.$can('index', 'CorporateAccount')) {
      await this.$router.push({ name: 'corporate_accounts' }).catch(() => { /**/ })
    } else if (this.$who.isCorporateAdministrator(this.$currentUser)) {
      await this.$router.push({ name: 'corporate_accounts_edit', params: { id: this.$currentUser.corporateAccountId } }).catch(() => { /**/ })
    }
    return '#'
  }

  public async corporateAccountsRedirects () {
    if (!this.$who.isRegularUser(this.$currentUser)) {
      await this.corporateAccountsLink()
    } else if (this.$who.isRegularUser(this.$currentUser) && !this.$currentUser.corporateAccountId) {
      await this.upgradeToCorporateUser(this.$currentUser.id, this.$currentUser.company)
    }
    return '#'
  }

  public whiteLabelRedirects () {
    if (this.$who.adminCSorAccounting(this.$currentUser.role) || this.$who.isVendorStaff(this.$currentUser)) {
      window.location.href=`${this.$redirectUrl}white_labels`
    } else if (this.$who.isCorporateAdministrator(this.$currentUser) || this.$who.isWhiteLabelAdmin(this.$currentUser)) {
      if (this.$currentUser.whiteLabelIds) {
        if (this.$currentUser.whiteLabelIds.length === 0) {
          window.location.href=`${this.$redirectUrl}white_labels/new`
        } else if (this.$currentUser.whiteLabelIds.length > 1) {
          window.location.href=`${this.$redirectUrl}white_labels`
        } else if (this.$currentUser.whiteLabelIds.length === 1) {
          window.location.href=`${this.$redirectUrl}white_labels/${this.$currentUser.whiteLabelIds[0]}/edit`
        }
      }
    } else if (!this.$currentUser.whiteLabelId) {
      this.$modal.show({
        type: 'success',
        headerText: this.$t('corporate_account_modal.title'),
        centered: true,
        side: false,
        scrollable: true,
        applyBtnText: this.$t('corporate_account_modal.apply_btn_text'),
        cancelBtnText: this.$t('corporate_account_modal.cancel_btn_text'),
        applyAction: () => { /**/ },
        cancelAction: () => { /**/ },
        props: { router: this.$router, userID: this.$currentUser.id, currentCompany: this.$currentUser.company, newWhiteLabel: true },
        component: CorporateAccountModal
      })
    }
    return '#'
  }

  public prepareDashboardsLinks () {
    let lastMenuIndex: number = this.navigation.length

    this.navigation.push({
      name: this.$t('dashboards_name'),
      icon: 'columns',
      children: []
    })

    if (this.$who.hasAccessToDashboard(this.dashboardsNames.SALES_DASHBOARD, this.$currentUser)) {
      this.navigation[lastMenuIndex].children.push({
        name: this.$t('dashboards_submenu.sales_dashboard_name'),
        to: '/sales_dashboard'
      })
    }

    if (this.$who.hasAccessToDashboard(this.dashboardsNames.SALES_BY_ACCOUNT_DASHBOARD, this.$currentUser)) {
      this.navigation[lastMenuIndex].children.push({
        name: this.$t('dashboards_submenu.sales_by_account_dashboard_name'),
        to: '/sales_by_account_dashboard'
      })
    }

    if (this.$who.hasAccessToDashboard(this.dashboardsNames.CLAIM_COLLECTION_DASHBOARD, this.$currentUser)) {
      this.navigation[lastMenuIndex].children.push({
        name: this.$t('dashboards_submenu.claim_and_collection_dashboard_name'),
        to: '/claim_collection_dashboard'
      })
    }

    if (this.$who.hasAccessToDashboard(this.dashboardsNames.CUSTOMER_SERVICE_DASHBOARD, this.$currentUser)) {
      this.navigation[lastMenuIndex].children.push({
        name: this.$t('dashboards_submenu.customer_service_dashboard_name'),
        to: '/customer_service_dashboard'
      })
    }

    if (this.$who.hasAccessToDashboard(this.dashboardsNames.CARRIER_INVOICING_DASHBOARD, this.$currentUser)) {
      this.navigation[lastMenuIndex].children.push({
        name: this.$t('dashboards_submenu.carrier_invoicing_dashboard_name'),
        to: '/carrier_invoicing_dashboard'
      })
    }

    if (this.$who.hasAccessToDashboard(this.dashboardsNames.ACCOUNTING_DASHBOARD, this.$currentUser)) {
      this.navigation[lastMenuIndex].children.push({
        name: this.$t('dashboards_submenu.accounting_dashboard_name'),
        to: '/accounting_dashboard'
      })
    }

    if (this.$who.hasAccessToDashboard(this.dashboardsNames.ADMINISTRATION_DASHBOARD, this.$currentUser)) {
      this.navigation[lastMenuIndex].children.push({
        name: this.$t('dashboards_submenu.administration_dashboard_name'),
        to: '/administration_dashboard'
      })
    }

    if (this.$currentUser.role !== UserRoles.USER_ROLE_VENDOR_SALES_AGENT) {
      this.navigation[lastMenuIndex].children.push({
        name: this.$t('dashboards_submenu.user_dashboard_name'),
        to: '/user_dashboard'
      })
    }
  }

  public prepareShopLinks () {
    let lastMenuIndex: number = this.navigation.length
    if (this.$can('manage', 'Shop') || (this.$can('index', 'SubsidyAmount') && !this.showSubsidiesForWL())) {
      this.navigation.push({
        name: this.$t('shops_name'),
        icon: 'store',
        children: []
      })

      if (this.myShopsAccessRules()) {
        this.navigation[lastMenuIndex].children.push({
          name: this.$t('shops_submenu.orders_name'),
          to: '/shops/orders'
        })
      }

      if (this.$can('index', 'SubsidyAmount') && !this.showSubsidiesForWL()) {
        this.navigation[lastMenuIndex].children.push({
          name: this.$t('shops_submenu.subsidies_name'),
          href: `${this.$redirectUrl}shipping/subsidy_amounts`
        })
      }

      if (this.myShopsAccessRules()) {
        this.navigation[lastMenuIndex].children.push({
          name: this.$t('shops_submenu.my_shops_name'),
          to: '/shops'
        })
      }

      if (this.$can('connect', 'Shop') && !this.$currentUser.shopsCount) {
        this.navigation[lastMenuIndex].children.push({
          name: this.$t('shops_submenu.connect_shop_name'),
          to: '/connect_shop'
        })
      }
    }
  }

  public prepareShipmentsLinks () {
    let lastMenuIndex: number = this.navigation.length
    this.navigation.push({
      name: this.$t('shipments_name'),
      icon: 'truck',
      children: []
    })

    if (this.$can('new', 'Shipment')) {
      this.navigation[lastMenuIndex].children.push({
        name: this.$t('shipments_submenu.new_shipment_name'),
        to: '/shipments/new'
      })
    }

    if (this.$can('new', 'Shipment')) {
      this.navigation[lastMenuIndex].children.push({
        name: this.$t('shipments_submenu.rate_shipment_name'),
        to: '/rate_shipment'
      })
    }

    if (this.$can('index', 'Shipment')) {
      this.navigation[lastMenuIndex].children.push({
        name: this.$t('shipments_submenu.my_shipments'),
        href: `${this.$redirectUrl}shipping/shipments`
      })
    }

    if (this.$can('index', 'Pickup') && this.currentUserDiscountProfileState()) {
      this.navigation[lastMenuIndex].children.push({
        name: this.$t('shipments_submenu.pickups_name'),
        href: `${this.$redirectUrl}shipping/pickups`
      })
    }

    this.navigation[lastMenuIndex].children.push({
      name: this.$t('shipments_submenu.templates_group_name')
    })

    if (this.$can('index', 'Packaging')) {
      this.navigation[lastMenuIndex].children.push({
        name: `${this.$t('shipments_submenu.packagings_name')}`,
        to: '/packagings',
        space: true
      })
    }

    if (this.$can('index', 'ShipmentTemplate')) {
      this.navigation[lastMenuIndex].children.push({
        name: `${this.$t('shipments_submenu.shipment_templates_name')}`,
        to: '/shipment_templates',
        space: true
      })
    }

    this.navigation[lastMenuIndex].children.push({
      name: this.$t('shipments_submenu.address_book_name')
    })

    if (this.$can('index', 'Direction')) {
      this.navigation[lastMenuIndex].children.push({
        name: `${this.$t('shipments_submenu.addresses_name')}`,
        to: '/directions',
        space: true
      })
    }

    if (this.$can('index', 'List')) {
      this.navigation[lastMenuIndex].children.push({
        name: `${this.$t('shipments_submenu.lists_name')}`,
        to: '/lists',
        space: true
      })
    }

    if (this.$can('index', 'Tag')) {
      this.navigation[lastMenuIndex].children.push({
        name: `${this.$t('shipments_submenu.tags_name')}`,
        to: '/tags',
        space: true
      })
    }
  }

  public prepareInvoicingLinks () {
    let lastMenuIndex: number = this.navigation.length
    this.navigation.push({
      name: this.$t('invoicing_name'),
      icon: 'file-invoice-dollar',
      children: []
    })

    this.navigation[lastMenuIndex].children.push({
      name: this.$t('invoicing_submenu.saldo_name'),
      href: `${this.$redirectUrl}shipping/credit_orders`
    })

    if (this.$can('index', 'Order')) {
      this.navigation[lastMenuIndex].children.push({
        name: this.$t('invoicing_submenu.orders_name'),
        href: `${this.$redirectUrl}shipping/orders`
      })
    }

    if (this.$can('index', 'Invoice')) {
      this.navigation[lastMenuIndex].children.push({
        name: this.$t('invoicing_submenu.invoices_name'),
        href: `${this.$redirectUrl}shipping/invoices`
      })
    }

    if (this.$can('index', 'Payment')) {
      this.navigation[lastMenuIndex].children.push({
        name: this.$t('invoicing_submenu.payments_name'),
        href: `${this.$redirectUrl}shipping/payments`
      })
    }

    if (this.$can('index', 'ClearingCharge')) {
      this.navigation[lastMenuIndex].children.push({
        name: this.$t('invoicing_submenu.clearing_charges_name'),
        to: '/clearing-charges'
      })
    }

    if (this.$can('index', 'InvoiceDirection')) {
      this.navigation[lastMenuIndex].children.push({
        name: this.$t('invoicing_submenu.invoice_directions_name'),
        to: '/invoice_directions'
      })
    }

    let invoice: Invoice = plainToClass(Invoice, { invoice_type: 4 })

    if (this.$can('index', invoice)) {
      this.navigation[lastMenuIndex].children.push({
        name: this.$t('invoicing_submenu.carrier_invoices_name'),
        href: `${this.$redirectUrl}shipping/invoices?invoice_type=4`
      })
    }

    if (this.$can('new', invoice)) {
      this.navigation[lastMenuIndex].children.push({
        name: this.$t('invoicing_submenu.import_carrier_invoice'),
        to: '/invoices/import'
      })
    }
  }

  public prepareResellerLinks () {
    if (this.$currentUser.adminableWhitelabels
      && this.$currentUser.adminableWhitelabels.length > 0
      && (this.$can('edit_reseller', this.$currentUser.adminableWhitelabels[0])
        || this.$can('show_reseller', this.$currentUser.adminableWhitelabels[0])
        || this.$can('index', 'WhiteLabel'))) {
      this.navigation.push({
        name: this.$t('reseller_name'),
        icon: 'address-book',
        href: this.resellerLink()
      })
    }
  }

  public prepareConfigurationLinks () {
    let lastMenuIndex: number = this.navigation.length
    this.navigation.push({
      name: this.$t('configuration_name'),
      icon: 'cog',
      children: []
    })

    let hasCorporateCarrierAccounts: Record<any, any> = get(this.dataForConfiguration, 'data.data.me.corporate_account')
    hasCorporateCarrierAccounts = hasCorporateCarrierAccounts?.carrier_accounts instanceof Array && hasCorporateCarrierAccounts?.carrier_accounts?.find( (carrierAccount: { alias_name: string }) => carrierAccount.alias_name )

    if (this.hasAccessToCarriersGroupName()) {
      this.navigation[lastMenuIndex].children.push({
        name: this.$t('configuration_submenu.carriers_group_name'),
        to: this.$who.isManagement(this.$currentUser.role) || this.$currentUser.hasOwnCarrierAccounts || hasCorporateCarrierAccounts ? undefined : '/carriers/edit'
      })

      let carriersGroup: any[] = []

      if (this.$can('index', 'QuotingFilter')) {
        carriersGroup.push({
          name: `${this.$t('configuration_submenu.shipment_filters_name')}`,
          to: '/carriers/edit',
          space: true
        })
      }

      if (this.$can('index', 'CarrierAccount')) {
        carriersGroup.push({
          name: `${this.$t('configuration_submenu.carrier_accounts_name')}`,
          to: '/carrier_accounts',
          space: true
        })
      }

      if (this.$can('index', 'CarrierServiceStandardCogsConfiguration')) {
        carriersGroup.push({
          name: `${this.$t('configuration_submenu.cogs_name')}`,
          to: '/cogs_configuration',
          space: true
        })
      }

      if (this.$can('index', 'AssignableCarrierTrackingNumber')) {
        carriersGroup.push({
          name: `${this.$t('configuration_submenu.carrier_tracking_numbers_name')}`,
          href: `${this.$redirectUrl}shipping/assignable_carrier_tracking_numbers`,
          space: true
        })
      }

      if (this.$can('index', 'LocationRestriction')) {
        carriersGroup.push({
          name: `${this.$t('configuration_submenu.location_restrictions')}`,
          to: '/location_restrictions',
          space: true
        })
      }

      if (carriersGroup.length === 1 && carriersGroup[0].name === this.$t('configuration_submenu.shipment_filters_name')) {
        this.navigation[lastMenuIndex].children[0].name = this.$t('configuration_submenu.carriers_group_name')
      } else {
        this.navigation[lastMenuIndex].children.push(...carriersGroup)
      }
    }

    if (this.$can('index', 'EnviayaAccount')) {
      this.navigation[lastMenuIndex].children.push({
        name: `${this.$t('configuration_submenu.accounts_name')}`,
        to: '/accounts'
      })
    }

    if (this.$can('index', 'User')) {
      this.navigation[lastMenuIndex].children.push({
        name: `${this.$t('configuration_submenu.users_name')}`,
        to: '/users'
      })
    }

    if (this.$can('index', 'CorporateAccount')
      || this.$who.isCorporateAdministrator(this.$currentUser)
      || (this.$currentUser.role === 1 && !this.$currentUser.corporateAccountId)
    ) {
      this.navigation[lastMenuIndex].children.push({
        name: this.$who.isManagement(this.$currentUser.role) ? `${this.$t('configuration_submenu.corporate_account_plural_name')}` : `${this.$t('configuration_submenu.corporate_account_name')}`,
        action: this.corporateAccountsRedirects
      })
    }

    if (this.whiteLabelAccessRules()) {
      this.navigation[lastMenuIndex].children.push({
        name: `${this.$t('configuration_submenu.white_label_name')}`,
        action: this.whiteLabelRedirects
      })
    }

    if (this.$can('index', 'SubsidyAmount') && this.showSubsidiesForWL()) {
      this.navigation[lastMenuIndex].children.push({
        name: this.$t('shops_submenu.subsidies_name'),
        href: `${this.$redirectUrl}shipping/subsidy_amounts`
      })
    }

    this.navigation[lastMenuIndex].children.push({
      name: `${this.$t('configuration_submenu.my_profile_name')}`,
      to: '/settings#contact_information'
    })
  }

  public prepareDevelopersLinks () {
    let lastMenuIndex: number = this.navigation.length
    this.navigation.push({
      name: this.$t('developers_name'),
      fab: true,
      icon: 'dev',
      children: []
    })

    if (this.$can('index', 'ApiKey')) {
      this.navigation[lastMenuIndex].children.push({
        name: `${this.$t('developers_submenu.api')}`,
        to: '/api_keys'
      })
    }

    if (this.$can('index', 'WebhookConfiguration')) {
      this.navigation[lastMenuIndex].children.push({
        name: `${this.$t('developers_submenu.webhooks_name')}`,
        href: `${this.$redirectUrl}shipping/webhook_configurations`
      })
    }
  }

  public prepareAssociatesLinks () {
    if (this.$can('manage', 'Referrer')) {
      this.navigation.push({
        name: this.$t('associates_name'),
        icon: 'users',
        to: '/associates'
      })
    }
  }

  public prepareSystemLinks () {
    let lastMenuIndex: number = this.navigation.length
    if (this.systemMenuAvailable()) {
      this.navigation.push({
        name: this.$t('system_name'),
        icon: 'coins',
        children: []
      })

      if (this.$can('index', 'BlockedPaymentOption')) {
        this.navigation[lastMenuIndex].children.push({
          name: this.$t('system_submenu.payment_restrictions_name'),
          href: `${this.$redirectUrl}shipping/blocked_payment_options`
        })
      }

      if (this.$currentUser.role >= 90 || this.$can('index', 'Service') || this.$can('index', 'AdditionalService') || this.$can('index', 'DiscountProfile')) {
        this.navigation[lastMenuIndex].children.push({
          name: this.$t('system_submenu.pricing_group_name'),
          to: '/discount-profiles/adjust'
        })

        if (this.$can('index', 'Service')) {
          this.navigation[lastMenuIndex].children.push({
            name: this.$t('system_submenu.customer_rates'),
            to: '/customer_rates',
            space: true
          })
        }

        if (this.$can('index', 'Service')) {
          this.navigation[lastMenuIndex].children.push({
            name: this.$t('system_submenu.services_name'),
            to: '/services',
            space: true
          })
        }

        if (this.$can('index', 'AdditionalService')) {
          this.navigation[lastMenuIndex].children.push({
            name: this.$t('system_submenu.surcharges_name'),
            href: `${this.$redirectUrl}shipping/additional_services`,
            space: true
          })
        }

        if (this.$who.isAdmin(this.$currentUser.role)) {
          this.navigation[lastMenuIndex].children.push({
            name: this.$t('system_submenu.fuel_surcharges'),
            to: '/fuel_surcharges',
            space: true
          })
        }

        if (this.$can('index', 'DiscountProfile')) {
          this.navigation[lastMenuIndex].children.push({
            name: this.$t('system_submenu.discount_profiles_name'),
            to: '/discount-profiles',
            space: true
          })
        }

        if (this.$currentUser.role >= 90) {
          this.navigation[lastMenuIndex].children.push({
            name: this.$t('system_submenu.carrier_rates'),
            to: '/carrier_rates',
            space: true
          })
        }
      }

      if (this.$who.isAdmin(this.$currentUser.role)) {
        this.navigation[lastMenuIndex].children.push({
          name: this.$t('system_submenu.catalogues_group_name')
        })

        if (this.$can('index', 'CurrencyExchangeRate')) {
          this.navigation[lastMenuIndex].children.push({
            name: this.$t('system_submenu.exchange_rates_name'),
            href: `${this.$redirectUrl}shipping/currency_exchange_rates`,
            space: true
          })
        }

        if (this.$can('index', 'TrackingEvent')) {
          this.navigation[lastMenuIndex].children.push({
            name: this.$t('system_submenu.tracking_events_name'),
            href: `${this.$redirectUrl}shipping/tracking_events`,
            space: true
          })
        }

        if (this.$currentUser.role >= 90 ) {
          this.navigation[lastMenuIndex].children.push({
            name: this.$t('system_submenu.address_metas'),
            href: `${this.$redirectUrl}shipping/address_metas`,
            space: true
          })
        }

        if (this.$who.isManagement(this.$currentUser.role) ) {
          this.navigation[lastMenuIndex].children.push({
            name: this.$t('system_submenu.access_points'),
            to: '/access_points',
            space: true
          })
        }
      }

      if (this.$can('index', 'ConversionTracking')) {
        this.navigation[lastMenuIndex].children.push({
          name: this.$t('system_submenu.conversions_name'),
          href: `${this.$redirectUrl}shipping/conversion_trackings`
        })
      }

      if (this.$who.isAdmin(this.$currentUser.role)) {
        this.navigation[lastMenuIndex].children.push({
          name: this.$t('system_submenu.imports'),
          to: '/data_import'
        })
      }

      if (this.$who.isAdmin(this.$currentUser.role)) {
        this.navigation[lastMenuIndex].children.push({
          name: this.$t('system_submenu.maintenance'),
          to: '/maintenance'
        })
      }
    }
  }

  public prepareHelpLinks () {
    if (this.$currentUser.helpDeskLink) {
      this.navigation.push({
        name: this.$t('help_center_name'),
        icon: 'question-circle',
        href: this.$currentUser.helpDeskLink,
        target: '_blank'
      })
    }
  }

  public async upgradeToCorporateUser (userID: number, company: string) {
    this.$modal.show({
      type: 'success',
      headerText: this.$t('corporate_user_modal.title'),
      centered: true,
      side: false,
      scrollable: true,
      applyBtnText: this.$t('corporate_user_modal.apply_btn_text'),
      cancelBtnText: this.$t('corporate_user_modal.cancel_btn_text'),
      applyAction: () => { /**/ },
      cancelAction: () => { /**/ },
      props: { router: this.$router, userID: userID, currentCompany: company, newWhiteLabel: false },
      component: CorporateAccountModal
    })
  }

  public async redirectToMain () {
    await this.$router.push('/').catch(() => { /**/ })
  }

  public systemMenuAvailable () {
    return this.$can('index', 'BlockedPaymentOption')
      || this.$can('index', 'Service')
      || this.$can('index', 'AdditionalService')
      || this.$can('index', 'DiscountProfile')
      || this.$can('index', 'CurrencyExchangeRate')
      || this.$can('index', 'TrackingEvent')
      || this.$can('index', 'ConversionTracking')
      || this.$currentUser.role >= 90
  }

  public hasAccessToCarriersGroupName () {
    return this.$can('index', 'QuotingFilter')
      || this.$can('index', 'CarrierAccount')
      || this.$can('index', 'CarrierServiceStandardCogsConfiguration')
      || this.$can('index', 'AssignableCarrierTrackingNumber')
      || this.$can('index', 'LocationRestriction')
  }

  @Watch('$route')
  public async abilitiesChanged () {
    if (this.$route.name === 'welcome') {
      this.navigation = []
      this.prepareDashboardsLinks()
      this.prepareShopLinks()
      this.prepareShipmentsLinks()
      this.prepareInvoicingLinks()
      this.prepareResellerLinks()
      this.prepareConfigurationLinks()
      this.prepareDevelopersLinks()
      this.prepareAssociatesLinks()
      this.prepareSystemLinks()
      this.prepareHelpLinks()

      await this.fetchUnpaidOrders()
    }
    // @ts-ignore
    this.applyStyles()
  }
}
