import '@fortawesome/fontawesome-pro/css/all.min.css'
import '@fortawesome/fontawesome-pro/css/light.css'
import '@fortawesome/fontawesome-pro/css/solid.css'
import '@fortawesome/fontawesome-pro/css/brands.css'
import '@fortawesome/fontawesome-pro/css/regular.css'
import '@fortawesome/fontawesome-pro/css/sharp-regular.css'
import 'reflect-metadata'
// @ts-ignore
import mainMenuLocaleData from '@/locales/main_menu.json'
// @ts-ignore
import currencyLocaleData from '@/locales/currency'
// @ts-ignore
import propertiesLocaleData from '@/locales/clearing-charges/properties.json'
// @ts-ignore
import clearingChargesIndexLocaleData from '@/locales/clearing-charges/index.json'
// @ts-ignore
import revisionStatusesLocaleData from '@/locales/revision_status.json'
// @ts-ignore
import accountingStatusesLocaleData from '@/locales/accounting_status.json'
// @ts-ignore
import carrierAccountingStatusesLocaleData from '@/locales/carrier_accounting_status.json'
// @ts-ignore
import verificationStatusesLocaleData from '@/locales/select_box_data/verification_statuses_select_box.json'
// @ts-ignore
import userStatusesLocaleData from '@/locales/user_statuses.json'
// @ts-ignore
import corporateAccountIndexLocaleData from '@/locales/corporate_accounts/index.json'
// @ts-ignore
import corporateAccountAdvancedConfigurationLocaleData from '@/locales/corporate_accounts/advanced_configuration.json'
// @ts-ignore
import userSettingsTabLocaleData from '@/locales/user_settings/settings_tab.json'
// @ts-ignore
import countriesLocaleData from '@/locales/select_box_data/countries_select_box.json'
// @ts-ignore
import cogsCalculationFormLocaleData from '@/locales/cogs-calculation/form.json'
// @ts-ignore
import calculationTypesLocaleData from '@/locales/select_box_data/calculation_types.json'
// @ts-ignore
import passwordSecurityLocaleData from '@/locales/password_security_options.json'

import PortalVue from 'portal-vue'
import Vue from 'vue'
import App from './App.vue'
import VueGtm from 'vue-gtm'
import router from './router'
import store from './store'
// import './registerServiceWorker'
import SideBar from './components/SideBar.vue'
import SiteHeader from './components/SiteHeader.vue'
import SiteFooter from './components/SiteFooter.vue'
import TopBar from './components/shipments/TopBar.vue'
import FilterBar from './components/FilterBar.vue'
import ExtendedFilters from './components/ExtendedFilters.vue'
import DateRangePicker from './components/date-range-picker/DateRangePicker.vue'
import { abilitiesPlugin } from '@casl/vue'
import i18n from './i18n'
import './filters/filters'
import ApiService from './common/api/api.service'
import GraphQLService from './common/api/graphql.service'
import DirectionSelectBox from './components/select-boxes/DirectionSelectBox.vue'
// TODO needs to be removed
// @ts-ignore
import ability from './common/ability'
import ConfigurationPlugin, { CONFIGURATION_KEYS } from './common/config'
import Moment from 'moment-timezone'
import VueMoment from 'vue-moment'
import VueMeta from 'vue-meta'
// TODO needs to be removed
// @ts-ignore
import Notify from 'mdbvue/lib/components/Notify'
// TODO needs to be removed
// @ts-ignore

import VueAuthenticate from 'vue-authenticate'
let VueScrollTo = require('vue-scrollto')

require('moment/min/locales.min')

Vue.config.productionTip = false
// @ts-ignore
import VueTelInput from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css'
import VueClipboard from 'vue-clipboard2'
Vue.use(ConfigurationPlugin)
Vue.use(VueClipboard)
Vue.use(PortalVue)
Vue.use(VueTelInput)
Vue.use(VueScrollTo, {
  container: 'body',
  duration: 500,
  easing: 'ease',
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
})

import './global-components'

Vue.component('app-sidebar', SideBar)
Vue.component('app-header', SiteHeader)
Vue.component('app-footer', SiteFooter)
Vue.component('top-bar', TopBar)
Vue.component('filter-bar', FilterBar)
Vue.component('extended-filters', ExtendedFilters)
Vue.component('date-range-picker', DateRangePicker)
Vue.component('direction-select-box', DirectionSelectBox)
Vue.component('carrier-accounts-select-box', CarrierAccountsSelectBox)

import VueCookies from 'vue-cookies-ts'
import { AuthModule } from '@/store/auth.module'
import { DomainService } from '@/common/domain.service'
import { setCookie } from '@/overrides/cookie-set-method'
import { AVAILABLE_LANGUAGES, LOCALE_BY_COUNTRY } from '@/common/user.settings'

// TODO needs to be removed
// @ts-ignore
import Modals from './plugins/Modals.js'
// @ts-ignore
import Who from './plugins/who.js'
// @ts-ignore
import queryClient from './plugins/query-client.js'
// @ts-ignore
import currencyHelper from './plugins/currency-helper.js'
// @ts-ignore
import GlobalData from './mixins/global-data.js'
// @ts-ignore
import MissingProfileState from './plugins/missing-profile-state.js'
// @ts-ignore
import RedirectUrl from './mixins/redirect-url.js'
// @ts-ignore
import Rollbar from './plugins/rollbar.js'
import { WhiteLabel } from '@/models/white-label'
import { convertAbilities } from './common/utility'
import { UsersApiService } from '@/common/api/users.api.service'
import CarrierAccountsSelectBox from '@/components/select-boxes/CarrierAccountsSelectBox.vue'
import { VueQueryPlugin } from '@tanstack/vue-query'
Vue.use(VueQueryPlugin, { queryClientConfig: { defaultOptions: { queries: { staleTime: 1000 * 60 * 2, refetchOnWindowFocus: false } } } })
import './quasar'

Vue.use(Modals)

if (Vue.$getConst(CONFIGURATION_KEYS.APP_ENVIRONMENT) === 'production' || Vue.$getConst(CONFIGURATION_KEYS.APP_ENVIRONMENT) === 'staging') {
  Vue.use(Rollbar)
}

window.onerror = function (message, _source,_line, _column, _error) {
  if (Vue.$getConst(CONFIGURATION_KEYS.APP_ENVIRONMENT) === 'production' || Vue.$getConst(CONFIGURATION_KEYS.APP_ENVIRONMENT) === 'staging') {
    Vue.$rollbar.log(message)
  } else {
    console.error(message)
  }
}

Vue.config.errorHandler = (err, _vm, _info) => {
  if (Vue.$getConst(CONFIGURATION_KEYS.APP_ENVIRONMENT) === 'production' || Vue.$getConst(CONFIGURATION_KEYS.APP_ENVIRONMENT) === 'staging') {
    Vue.$rollbar.log(err)
  } else {
    console.error(err)
  }
}

Vue.use(abilitiesPlugin, ability, { useGlobalProperties: true })
Vue.use(VueMeta)

Vue.use(VueCookies)
Vue.prototype.$cookies.set = setCookie

Vue.use(VueMoment, { moment: Moment })

Vue.use(Who)
Vue.use(queryClient)
Vue.use(currencyHelper)
Vue.mixin(GlobalData)
Vue.use(MissingProfileState)
Vue.mixin(RedirectUrl)
Vue.use(Notify)

ApiService.init();
(async () => {
  if (store.getters.currentUser && store.getters.currentUser.locale) {
    await store.dispatch('updateLocale', store.getters.currentUser.locale)
  } else if (Vue.cookies.get('locale')) {
    await store.dispatch('updateLocale', Vue.cookies.get('locale'))
  } else {
    const { data } = await UsersApiService.getCountryByIp()
    if (data && data.country && data.country.length && LOCALE_BY_COUNTRY[data.country.toLowerCase()]) {
      await store.dispatch('updateLocale', LOCALE_BY_COUNTRY[data.country.toLowerCase()])
    } else if (window.navigator.language && AVAILABLE_LANGUAGES.includes(window.navigator.language.split('-')[0])) {
      await store.dispatch('updateLocale', window.navigator.language.split('-')[0])
    } else {
      await store.dispatch('updateLocale', 'en')
    }
  }
})().catch( async () => {
  await store.dispatch('updateLocale', 'en')
})

GraphQLService.init()

function initSocialLogin (providers: any) {
  Vue.use(VueAuthenticate, providers)
}

function initGTM (token: any, enable: boolean = true) {
  Vue.use(VueGtm, {
    id: token,
    compatibility: false,
    defer: true,
    loadScript: true,
    enabled: enable && ['production', 'staging'].includes(Vue.$getConst(CONFIGURATION_KEYS.APP_ENVIRONMENT)), // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
    debug: false, // Whether or not display console logs debugs (optional)
    vueRouter: router, // Pass the router instance to automatically sync with router (optional)
    ignoredViews: [
      'maintenance_mode',
      'access_denied',
      'resource_not_found',
      'not_found'
    ] // If router, you can exclude some routes name (case insensitive) (optional)
  })
}

router.beforeEach(async (to, from, next) => {
  let whiteLabel: WhiteLabel | null = null
  if (AuthModule.currentWhiteLabel) {
    whiteLabel = AuthModule.currentWhiteLabel as WhiteLabel
    if (whiteLabel.googleTagManagerKey) {
      initGTM(whiteLabel.googleTagManagerKey)
    } else {
      initGTM(Vue.$getConst(CONFIGURATION_KEYS.GOOGLE_TAG_MANAGER_ID), !whiteLabel.enableReseller)
    }
  } else {
    initGTM(Vue.$getConst(CONFIGURATION_KEYS.GOOGLE_TAG_MANAGER_ID))
  }

  if (to.query.locale) {
    if (AVAILABLE_LANGUAGES.includes((to.query.locale as string).split('-')[0])) {
      await store.dispatch('updateLocale', (to.query.locale as string).split('-')[0])
    } else {
      await store.dispatch('updateLocale', 'es')
    }
  }

  let layerData: Object = {}
  if (AuthModule.currentUser) {
    layerData = {
      'uuid': AuthModule.currentUser.uuid,
      'name': `${AuthModule.currentUser.firstName} ${AuthModule.currentUser.lastName}`,
      'email': AuthModule.currentUser.email,
      'phone': AuthModule.currentUser.phone,
      'level': AuthModule.currentUser.discountProfile ? AuthModule.currentUser.discountProfile.level : '',
      'white-label-id': (whiteLabel) ? whiteLabel.id : null,
      'distributor': !!(whiteLabel && whiteLabel.enableReseller),
      'vip_support_agent': (AuthModule.currentUser && AuthModule.currentUser.vipSupportEmail) ? AuthModule.currentUser.vipSupportEmail : '',
      'rmy': (AuthModule.currentUser.rmy) ? AuthModule.currentUser.rmy : '',
      'dsr': (AuthModule.currentUser.dsr) ? AuthModule.currentUser.dsr : '',
      'last_shipment': (AuthModule.currentUser.lastShipmentDate) ? AuthModule.currentUser.lastShipmentDate : ''
    }

    Vue.cookies.set('eymkt', JSON.stringify(layerData), { ...DomainService.getDomainForCookies(whiteLabel), secure: false })
  }

  // @ts-ignore
  if (window.gtag && typeof window.gtag === 'function') {
    // @ts-ignore
    if (window.dataLayer && window.dataLayer.length) {
      // @ts-ignore
      let chatEvent = window.dataLayer.findIndex(layer => layer.event === 'gtm.js')
      if (chatEvent !== -1 && AuthModule.currentUser) {
        // @ts-ignore
        Object.assign(window.dataLayer[chatEvent], layerData)
      }
    }
  }

  if (to.query.kw && to.query.kw.length > 0 && to.query.kw !== Vue.cookies.get('kw')) {
    Vue.cookies.set('kw', to.query.kw, { ...DomainService.getDomainForCookies(whiteLabel), secure: false })
  }
  if (to.query.referrer_key && to.query.referrer_key.length > 0 && !Vue.cookies.get('referrer_key')) {
    Vue.cookies.set('referrer_key', to.query.referrer_key, { ...DomainService.getDomainForCookies(whiteLabel), secure: false })
  }
  if (to.query.sales_executive && to.query.sales_executive.length > 0) {
    Vue.cookies.set('sales_executive', to.query.sales_executive, { ...DomainService.getDomainForCookies(whiteLabel), secure: false })
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!AuthModule.currentUser) {
      try {
        let { data } = await AuthModule.tokenLogin()
        router.app.$ability.update(convertAbilities(data.user.abilities))
        if (!data.user.two_factor_authenticated) {
          next('/mfa_code')
        } else {
          next('/')
        }
      } catch (e) { /**/ }

      if (to.path !== '/' && to.path !== '/login') {
        Vue.cookies.set('redirectUrl', encodeURI(to.fullPath).replace('#', '%23'))
        next(`/login`)
      } else {
        next('/login')
      }
    } else {
      if (to.name !== from.name) {
        store.dispatch('updatePreloader', true).catch(() => { /**/ })
        await AuthModule.sessionCheck().then(({ status }) => {
          if (status === 200) {
            if (AuthModule.currentUser && !AuthModule.currentUser.twoFactorAuthenticated) {
              next('/mfa_code')
            } else {
              if (AuthModule.currentUser && !AuthModule.currentUser.email && to.name !== 'Settings') {
                next('settings?not_validated=true' )
              } else {
                if (to && to.name !== from.name && AuthModule.currentUser) {
                  router.app.$profileValidator.checkUserProfile(AuthModule.currentUser, ability)
                }
                next()
              }
            }
          }
        }).catch(() => {
          /**/
        })
      } else {
        next()
      }
    }
  } else {
    if (['public_quote', 'EmailChangeConfirmation', 'PasswordRecovery', 'PasswordReset', 'Logout', 'MfaCode', 'not_found', 'resource_not_found', 'access_denied', 'maintenance_mode', 'internal_server_error'].includes(to.name!.toString())) {
      next()
      return
    }

    if (AuthModule.currentUser) {
      if (AuthModule.currentUser.twoFactorAuthenticated) {
        (to.name !== 'Welcome') ? next('/') : next()
      } else {
        (to.name !== 'MfaCode') ? next('/mfa_code') : next()
      }
    } else {
      if (AuthModule.currentUser && (to.name === 'Login' || to.name === 'MfaCode')) {
        try {
          let { data } = await AuthModule.tokenLogin()
          router.app.$ability.update(convertAbilities(data.user.abilities))
          if (!data.user.two_factor_authenticated) {
            next('/mfa_code')
          } else {
            next('/')
          }
          // @ts-ignore
          if (to.query && to.query.provider && window.gtag && typeof window.gtag === 'function') {
            let loginTime = Vue.moment().toDate()
            let registeredTime = Vue.moment(AuthModule.currentUser!.createdAt! * 1000).toDate()
            if (Vue.moment(loginTime).diff(Vue.moment(registeredTime), 'minutes') < 2) {
              switch (to.query.provider) {
                case 'google':
                  // @ts-ignore
                  window.gtag('event', 'sign_up', {
                    method: 'Google',
                    eventCategory: 'Sign up',
                    eventAction: 'Sign up (Confirmed)',
                    eventLabel: 'Google'
                  })
                  break
                case 'facebook':
                  // @ts-ignore
                  window.gtag('event', 'sign_up', {
                    method: 'Facebook',
                    eventCategory: 'Sign up',
                    eventAction: 'Sign up (Confirmed)',
                    eventLabel: 'Facebook'
                  })
                  break
              }
            }
          }
        } catch (e) { /**/ }
      } else {
        (to.name === 'MfaCode') ? next('/login') : next()
      }
    }
  }
})

router.afterEach((to) => {
  store.dispatch('updatePreloader', false).catch(() => { /**/ })
  if (to.name === 'Login') {
    if (router.app.$currentWhiteLabel) {
      initSocialLogin({
        providers: {
          facebook: {
            clientId: router.app.$currentWhiteLabel.oauthFacebookKey,
            redirectUri: `${router.app.$redirectUrl}oauth/callback?provider=facebook&state=vue`
          },
          google: {
            clientId: router.app.$currentWhiteLabel.googleOauthKey,
            redirectUri: `${router.app.$redirectUrl}}oauth/callback?provider=google&state=vue`
          }
        }
      })
    } else {
      initSocialLogin({
        providers: {
          facebook: {
            clientId: Vue.$getConst(CONFIGURATION_KEYS.APP_FACEBOOK_KEY),
            redirectUri: `${Vue.$getConst(CONFIGURATION_KEYS.REDIRECT_URL)}oauth/callback?provider=facebook&state=vue`
          },
          google: {
            clientId: Vue.$getConst(CONFIGURATION_KEYS.APP_GOOGLE_KEY),
            redirectUri: `${Vue.$getConst(CONFIGURATION_KEYS.REDIRECT_URL)}oauth/callback?provider=google&state=vue`
          }
        }
      })
    }
  }
})

router.afterEach((to) => {
  if (AuthModule.currentUser && AuthModule.currentUser.twoFactorAuthenticated) {
    if (to.name !== 'ShopConnected' && Vue.cookies.isKey('unassigned_shop')) {
      Vue.cookies.remove('unassigned_shop')
      window.location.href = `${router.app.$redirectUrl}shops/link_user/${Vue.cookies.get('unassigned_shop')}`
    }
  }
})

router.onError(async (error) => {
  if (/Loading chunk (.)+ failed./i.test(error.message) || /Loading CSS chunk (.)+ failed./i.test(error.message)) {
    Vue.$modal.show({
      type: 'warning',
      centered: true,
      headerText: 'Warning',
      bodyText: 'Application was updated. Press Ok to reload.',
      applyAction: () => {
        window.location.reload()
      }
    })
  }
})

i18n.mergeLocaleMessage('en', mainMenuLocaleData.en)
i18n.mergeLocaleMessage('de', mainMenuLocaleData.de)
i18n.mergeLocaleMessage('es', mainMenuLocaleData.es)

i18n.mergeLocaleMessage('en', currencyLocaleData.en)
i18n.mergeLocaleMessage('de', currencyLocaleData.de)
i18n.mergeLocaleMessage('es', currencyLocaleData.es)

i18n.mergeLocaleMessage('en', propertiesLocaleData.en)
i18n.mergeLocaleMessage('de', propertiesLocaleData.de)
i18n.mergeLocaleMessage('es', propertiesLocaleData.es)

i18n.mergeLocaleMessage('en', clearingChargesIndexLocaleData.en)
i18n.mergeLocaleMessage('de', clearingChargesIndexLocaleData.de)
i18n.mergeLocaleMessage('es', clearingChargesIndexLocaleData.es)

i18n.mergeLocaleMessage('en', revisionStatusesLocaleData.en)
i18n.mergeLocaleMessage('de', revisionStatusesLocaleData.de)
i18n.mergeLocaleMessage('es', revisionStatusesLocaleData.es)

i18n.mergeLocaleMessage('en', accountingStatusesLocaleData.en)
i18n.mergeLocaleMessage('de', accountingStatusesLocaleData.de)
i18n.mergeLocaleMessage('es', accountingStatusesLocaleData.es)

i18n.mergeLocaleMessage('en', carrierAccountingStatusesLocaleData.en)
i18n.mergeLocaleMessage('de', carrierAccountingStatusesLocaleData.de)
i18n.mergeLocaleMessage('es', carrierAccountingStatusesLocaleData.es)

i18n.mergeLocaleMessage('en', verificationStatusesLocaleData.en)
i18n.mergeLocaleMessage('de', verificationStatusesLocaleData.de)
i18n.mergeLocaleMessage('es', verificationStatusesLocaleData.es)

i18n.mergeLocaleMessage('en', userStatusesLocaleData.en)
i18n.mergeLocaleMessage('de', userStatusesLocaleData.de)
i18n.mergeLocaleMessage('es', userStatusesLocaleData.es)

i18n.mergeLocaleMessage('en', corporateAccountIndexLocaleData.en)
i18n.mergeLocaleMessage('de', corporateAccountIndexLocaleData.de)
i18n.mergeLocaleMessage('es', corporateAccountIndexLocaleData.es)

i18n.mergeLocaleMessage('en', corporateAccountAdvancedConfigurationLocaleData.en)
i18n.mergeLocaleMessage('de', corporateAccountAdvancedConfigurationLocaleData.de)
i18n.mergeLocaleMessage('es', corporateAccountAdvancedConfigurationLocaleData.es)

i18n.mergeLocaleMessage('en', userSettingsTabLocaleData.en)
i18n.mergeLocaleMessage('de', userSettingsTabLocaleData.de)
i18n.mergeLocaleMessage('es', userSettingsTabLocaleData.es)

i18n.mergeLocaleMessage('en', countriesLocaleData.en)
i18n.mergeLocaleMessage('de', countriesLocaleData.de)
i18n.mergeLocaleMessage('es', countriesLocaleData.es)

i18n.mergeLocaleMessage('en', cogsCalculationFormLocaleData.en)
i18n.mergeLocaleMessage('de', cogsCalculationFormLocaleData.de)
i18n.mergeLocaleMessage('es', cogsCalculationFormLocaleData.es)

i18n.mergeLocaleMessage('en', calculationTypesLocaleData.en)
i18n.mergeLocaleMessage('de', calculationTypesLocaleData.de)
i18n.mergeLocaleMessage('es', calculationTypesLocaleData.es)

i18n.mergeLocaleMessage('en', passwordSecurityLocaleData.en)
i18n.mergeLocaleMessage('de', passwordSecurityLocaleData.de)
i18n.mergeLocaleMessage('es', passwordSecurityLocaleData.es)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
