import { render, staticRenderFns } from "./SideBar.vue?vue&type=template&id=2783d6e3"
import script from "./SideBar.vue?vue&type=script&lang=ts"
export * from "./SideBar.vue?vue&type=script&lang=ts"
import style0 from "./SideBar.vue?vue&type=style&index=0&id=2783d6e3&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@/locales/corporate_account_modal.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2FSideBar.vue&external"
if (typeof block0 === 'function') block0(component)
import block1 from "@/locales/corporate_user_modal.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2FSideBar.vue&external"
if (typeof block1 === 'function') block1(component)

export default component.exports